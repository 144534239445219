import './index.scss' 

import React from 'react'

import Logo from 'images/logo.svg'

var App = () => {

  return (
    <div className="page">
      <div className="page-header">
        <div className="container">
          <div className="action-links"a>
            <a 
              className="action-link action-link--primary action-link--mobile"
              href="https://app.1will.pro"
            >
              Minha conta {" "}
            </a>
            <a 
              target="_blank"
              className="action-link"
              href="https://www.google.com/maps/d/embed?mid=1IlZdXxZ7t7AVELDXfgYbBHhr9-s&ll=-19.124847469582168%2C-45.94047184476415&z=6"
            >
              Onde estamos
            </a>
            <a 
              className="action-link"
              href="https://loja.1will.com.br"
            >
              Suplementos em casa
            </a>
            <a 
              className="action-link"
              href="https://www.instagram.com/fit.n.livin"
            >
              Nutricionista
            </a>
            <a 
              className="action-link action-link--primary action-link--desktop"
              href="https://app.1will.pro"
            >
              Minha conta →
            </a>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <a 
            className="splash"
            href="https://www.instagram.com/fit.n.livin"
          >
            <div className="splash__blink"></div>
            <div className="splash__logo">
              <img src={Logo} />
            </div>
            <div className="splash__title">
              Fitness sem deixar de viver.
            </div>
          </a>
        </div>
      </div>
      
      <div className="page-footer">
        <div className="footer">
          <div className="container">
            <div className="footer__social">
              <a 
                className="footer__social__a"
                href="https://www.instagram.com/fit.n.livin" 
              >
                Instagram
              </a> 
              {' '} • {' '}
              <a 
                className="footer__social__a"
                href="https://api.whatsapp.com/send?phone=5548984819796" 
              >
                WhatsApp (11) 99117-3788
              </a>
            </div>
            <div className="footer__copyright">
              Copyright © 1Will {new Date().getFullYear()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default App
